<template>
  <div class="order">
    <div class="search">
      <el-input class="w-100" v-model="search" placeholder="搜尋關鍵字"></el-input>
    </div>
    <div class="orderTable">
      <el-table :max-height="600" class="multipleTable" ref="multipleTable" :data="displayData" :default-sort="{ prop: 'createdDateTime', order: 'descending' }">
        <el-table-column type="expand" width="30">
          <template #default="props">
            <div class="my-3 orderContent" v-if="$store.getters.mobileSize">
              訂單編號：
              <span class="content" v-html="props.row.orderNo"></span>
            </div>
            <div class="my-3 orderContent" v-if="$store.getters.mobileSize">
              商品名稱：
              <span class="content" v-html="props.row.itemDesc"></span>
            </div>
            <div class="my-3 orderContent" v-if="$store.getters.mobileSize">
              消費金額：
              <span class="content" v-html="props.row.amount"></span><span>元</span>
            </div>
            <div class="my-3 orderContent">
              實際支付金額：
              <span class="content" v-html="props.row.amountAfterDiscount"></span><span>元</span>
            </div>
            <div class="my-3 orderContent">
              使用ChargeMe點數：
              <span class="content" v-html="props.row.chargeMePoint"></span><span>點</span>
            </div>
            <div class="my-3 orderContent">
              商品金額：
              <span class="content" v-html="props.row.productAmount"></span><span>元</span>
            </div>
            <div class="my-3 orderContent">
              商品補價差金額：
              <span class="content" v-html="props.row.spreadAmount"></span><span>元</span>
            </div>
            <div class="my-3 orderContent" v-if="$store.getters.mobileSize">
              交易狀態：
              <el-tag :type="props.row.isSuccessed ? 'success' : 'warning'" disable-transitions>{{ props.row.isSuccessed ? "成功" : "失敗" }}</el-tag>
            </div>
            <div class="my-3 orderContent" v-if="$store.getters.mobileSize">
              付款方式：
              <span class="content" v-html="props.row.paymentMethod"></span>
            </div>
            <div class="my-3 orderContent">
              交易前紀錄：
              <span v-if="props.row.productType == 'Membership'" class="content"
                >會籍 ⇒ {{ props.row.oldMembershipExpiredDateTime.split("T")[0] }} <span>{{ props.row.oldMembershipExpiredDateTime.split("T")[1].split(".")[0] }}</span></span
              >
              <span v-else class="content">Line點數 ⇒ {{ props.row.oldLineBotPoint }}點</span>
            </div>
            <div class="my-3 orderContent">
              交易後紀錄：
              <span v-if="props.row.productType == 'Membership'" class="content"
                >會籍 ⇒ {{ props.row.newMembershipExpiredDateTime.split("T")[0] }} <span>{{ props.row.newMembershipExpiredDateTime.split("T")[1].split(".")[0] }}</span></span
              >
              <span v-else class="content">Line點數 ⇒ {{ props.row.newLineBotPoint }}點</span>
            </div>
            <div class="my-3 orderContent">
              購買人姓名：
              <span class="content" v-html="props.row.buyerName"></span>
            </div>
            <div class="my-3 orderContent">
              購買人Email：
              <span class="content" v-html="props.row.email"></span>
            </div>
            <div class="my-3 orderContent" v-if="props.row.buyerAddress != ''">
              購買人地址：
              <span class="content" v-html="props.row.buyerAddress"></span>
            </div>
            <div class="my-3 orderContent" v-if="props.row.buyerUBN != ''">
              購買人統一編號：
              <span class="content" v-html="props.row.buyerUBN"></span>
            </div>
            <div class="my-3 orderContent" v-if="props.row.carrierType != ''">
              購買人載具類型：
              <span class="content" v-if="props.row.carrierType == '0'">手機條碼載具</span>
              <span class="content" v-else-if="props.row.carrierType == '1'">自然人憑證條碼載具</span>
              <span class="content" v-else-if="props.row.carrierType == '2'">ezPay 電子發票載具</span>
              <span class="content" v-else></span>
            </div>
            <div class="my-3 orderContent" v-if="props.row.carrierNum != ''">
              購買人載具號碼：
              <span class="content" v-html="props.row.carrierNum"></span>
            </div>
            <div class="my-3 orderContent" v-if="props.row.loveCode != ''">
              購買人捐贈碼：
              <span class="content" v-html="props.row.loveCode"></span>
            </div>
            <div class="my-3 orderContent">
              發票號碼：
              <span class="content" v-html="props.row.invoiceNumber"></span>
            </div>
            <div class="my-3 orderContent" v-if="props.row.errorCode != ''">
              錯誤代碼：
              <span class="content" v-html="props.row.errorCode"></span>
            </div>
            <div class="my-3 orderContent" v-if="props.row.errorMessage != ''">
              錯誤訊息：
              <span class="content" v-html="props.row.errorMessage"></span>
            </div>
          </template>
        </el-table-column>
        <el-table-column sortable prop="createdDateTime" sort-by="createdDateTime" label="購買日期">
          <template #default="scope">
            {{ scope.row.createdDateTime.split("T")[0] }} <span>{{ scope.row.createdDateTime.split("T")[1].split(".")[0] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderNo" label="訂單編號" sortable v-if="!$store.getters.mobileSize" />
        <el-table-column sortable prop="itemDesc" sort-by="itemDesc" label="購買項目">
          <template #default="scope">
            {{ scope.row.productType == "Membership" ? "會籍" : "Line點數" }}
          </template>
        </el-table-column>
        <el-table-column prop="itemDesc" label="商品名稱" sortable v-if="!$store.getters.mobileSize" width="300" />
        <el-table-column sortable prop="amount" sort-by="amount" label="消費金額" v-if="!$store.getters.mobileSize">
          <template #default="scope"> {{ scope.row.amount }}元 </template>
        </el-table-column>
        <el-table-column prop="isSuccessed" label="交易狀態" sortable v-if="!$store.getters.mobileSize">
          <template #default="scope">
            <el-tag :type="scope.row.isSuccessed ? 'success' : 'warning'" disable-transitions>{{ scope.row.isSuccessed ? "成功" : "失敗" }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="paymentMethod" label="付款方式" sortable v-if="!$store.getters.mobileSize" />
        <!-- <el-table-column>
            <template #header>
              <el-input v-model="search" size="small" placeholder="搜尋關鍵字" />
            </template>
          </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Order",
  data() {
    return {
      orderList: [],
      search: "",
    };
  },
  async mounted() {
    const vm = this;
    vm.initData();
  },
  computed: {
    displayData() {
      let displayData = this.searchData(this.orderList);
      return displayData;
    },
  },
  methods: {
    initData() {
      const vm = this;
      vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/Member/HistoryOrder`)
        .then(res => {
          console.log(res.data);
          vm.orderList = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    searchData(table) {
      return table.filter(
        data =>
          !this.search ||
          data.orderNo.toLowerCase().includes(this.search.toLowerCase()) ||
          data.itemDesc.toLowerCase().includes(this.search.toLowerCase()) ||
          data.amount.toString().toLowerCase().includes(this.search.toLowerCase()) ||
          data.paymentMethod.toLowerCase().includes(this.search.toLowerCase()) ||
          data.oldMembershipExpiredDateTime.toLowerCase().includes(this.search.toLowerCase()) ||
          data.oldLineBotPoint.toString().toLowerCase().includes(this.search.toLowerCase()) ||
          data.newMembershipExpiredDateTime.toLowerCase().includes(this.search.toLowerCase()) ||
          data.newLineBotPoint.toString().toLowerCase().includes(this.search.toLowerCase()) ||
          data.email.toLowerCase().includes(this.search.toLowerCase()) ||
          data.errorCode.toLowerCase().includes(this.search.toLowerCase()) ||
          data.errorMessage.toLowerCase().includes(this.search.toLowerCase()) ||
          data.createdDateTime.includes(this.search.toLowerCase()) ||
          data.amountAfterDiscount.toString().includes(this.search.toLowerCase()) ||
          data.productAmount.toString().includes(this.search.toLowerCase()) ||
          data.spreadAmount.toString().includes(this.search.toLowerCase()) ||
          data.chargeMePoint.toString().includes(this.search.toLowerCase()) ||
          data.buyerName.toLowerCase().includes(this.search.toLowerCase()) ||
          data.buyerAddress.toLowerCase().includes(this.search.toLowerCase()) ||
          data.buyerUBN.toLowerCase().includes(this.search.toLowerCase()) ||
          data.carrierNum.toLowerCase().includes(this.search.toLowerCase()) ||
          data.loveCode.toLowerCase().includes(this.search.toLowerCase()) ||
          data.invoiceNumber.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  width: 100%;
  margin: 0 auto;
  padding: 0 1.5rem 4rem;
  .pagination {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 3rem;
    .el-pagination {
      align-items: flex-end;
    }
  }
  .expended {
    .el-table__cell {
      padding: 0 2rem;
      background-color: #000;
    }
  }
  .orderContent {
    margin-left: 2.5rem;
    margin-right: 0.5rem;
    .content {
      padding-top: 0.5rem;
      word-wrap: break-word;
      line-height: 1.5;
    }
  }
}
.search {
  width: 100%;
  padding: 1rem 0;
  text-align: end;
  :deep .el-input {
    width: 20rem !important;
  }
}
:deep .editor .tox {
  z-index: 0 !important;
}
.orderTable {
  overflow-y: auto;
}
@media screen and (max-width: 768px) {
  .order {
    width: 100%;
    padding: 0 0.5rem 4rem;
  }
  .search {
    width: 100%;
    padding: 1rem;
    :deep .el-input {
      width: 100% !important;
    }
  }
}
</style>
